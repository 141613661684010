
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'PageNotFound',
  
  created(){
    window.location.href = "/404.html";
  }
});
